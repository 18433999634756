// @flow
import React, { useContext } from 'react';

export type DestinationMdl = {
  url: string,
  external: boolean,
};

export type PageContextState = {
  spotifyAccessToken: string,
  setSpotifyAccessToken: (token: string) => void,
  loadingSpotifyData: boolean,
  setLoadingSpotifyData: (loading: boolean) => void,
  premiumDestination: DestinationMdl | null,
  freemiumDestination: DestinationMdl | null,
  directDestination: DestinationMdl | null,
};

const defaultState: PageContextState = {
  spotifyAccessToken: '',
  setSpotifyAccessToken: () => {},
  loadingSpotifyData: false,
  setLoadingSpotifyData: () => {},
  premiumDestination: null,
  freemiumDestination: null,
  directDestination: null,
};

const PageContext = React.createContext(defaultState);

export default PageContext;

export const useGetPageContext = (): PageContextState => {
  return useContext(PageContext);
};

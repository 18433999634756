/* eslint-disable */
/**
 * Returns a bare object of the URL's query parameters.
 * You can pass just a query string rather than a complete URL.
 * The default URL is the current page.
 */
// https://gist.github.com/joshbuchea/a45c62f8c7bc5697b550
export function getUrlParams(url) {
  if (typeof window === `undefined`) {
    return {};
  }
  // http://stackoverflow.com/a/23946023/2407309
  if (typeof url === 'undefined') {
    url = window.location.search;
  }
  url = url.split('#')[0]; // Discard fragment identifier.
  const urlParams = {};
  let queryString = url.split('?')[1];
  if (!queryString) {
    if (url.search('=') !== false) {
      queryString = url;
    }
  }
  if (queryString) {
    const keyValuePairs = queryString.split('&');
    for (let i = 0; i < keyValuePairs.length; i++) {
      const keyValuePair = keyValuePairs[i].split('=');
      const paramName = keyValuePair[0];
      const paramValue = keyValuePair[1] || '';
      urlParams[paramName] = decodeURIComponent(paramValue.replace(/\+/g, ' '));
    }
  }
  return urlParams;
}

export function getUrlParam(param) {
  const params = getUrlParams();
  return params[param] || '';
}

// @flow
import React from 'react';
import Countdown from 'react-countdown-now';
import { get } from 'lodash';
import * as styles from './styles';
import { useCountdownTimerTimestamp } from '../../../../../CountdownTimerContext';
import type { LinkMdl } from '../../../../../../cms/models/pageComponents';
import { usePageComponentsViva } from '../../../../../WebsiteData';

const padNumber = (numberToPad: number): string => {
  if (numberToPad >= 10) return numberToPad.toString();
  return `0${numberToPad.toString()}`;
};

const useCountdownLabels = (): {
  days: string,
  hours: string,
  minutes: string,
  seconds: string,
} => {
  const pageComponents = usePageComponentsViva();
  if (!pageComponents) return [];
  const days = get(pageComponents, 'countdownLabels[0]', 'Days');
  const hours = get(pageComponents, 'countdownLabels[1]', 'Hours');
  const minutes = get(pageComponents, 'countdownLabels[2]', 'Minutes');
  const seconds = get(pageComponents, 'countdownLabels[3]', 'Seconds');
  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

const CountdownRenderer = ({ days, hours, minutes, seconds }) => {
  const countdownLabels = useCountdownLabels();
  // Render a countdown
  return (
    <div className={styles.containerClass}>
      <div className={styles.itemClass}>
        <div className={styles.valueClass}>{padNumber(days)}</div>
        <div className={styles.labelClass}>{countdownLabels.days}</div>
      </div>
      <div className={styles.itemClass}>
        <div className={styles.valueClass}>{padNumber(hours)}</div>
        <div className={styles.labelClass}>{countdownLabels.hours}</div>
      </div>
      <div className={styles.itemClass}>
        <div className={styles.valueClass}>{padNumber(minutes)}</div>
        <div className={styles.labelClass}>{countdownLabels.minutes}</div>
      </div>
      <div className={styles.itemClass}>
        <div className={styles.valueClass}>{padNumber(seconds)}</div>
        <div className={styles.labelClass}>{countdownLabels.seconds}</div>
      </div>
    </div>
  );
};

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return '...';
  }
  // Render a countdown
  return <CountdownRenderer days={days} hours={hours} minutes={minutes} seconds={seconds} />;
};

const VivaPageCountdown = () => {
  const timestamp = useCountdownTimerTimestamp();
  if (!timestamp) return null;
  return <Countdown date={Date.parse(timestamp)} renderer={renderer} />;
};

export default VivaPageCountdown;

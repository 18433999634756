// @flow
import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { useGetPageContext } from '../../context/PageContext';
import LoadingPage from '../LoadingPage';
import { getSpotifyProfile } from '../../../../spotify/api';
import type { SpotifyProfileModel } from '../../../../spotify/api';
import type { DestinationMdl } from '../../context/PageContext';
import {
  gaEventDestinationDirect,
  gaEventDestinationFreemium,
  gaEventDestinationPremium,
  gaEventUserIsFreemium,
  gaEventUserIsPremium,
  setGaSpotifyUserId,
} from '../../../../analytics';

const hasDontRedirect = (): boolean => {
  if (typeof window === `undefined`) {
    return false;
  }
  const params = queryString.parse(window.location.search);
  if (params.dontRedirect) {
    return true;
  }
  return false;
};

const sendUserToDestination = (destination: DestinationMdl) => {
  if (!hasDontRedirect()) {
    const { url, external } = destination;
    if (external) {
      if (typeof window === `undefined`) {
        return;
      }
      window.location.href = url;
    } else {
      navigate(url);
    }
  }
};

const fetchSpotifyProfileAndRedirect = (
  spotifyAccessToken: string,
  premiumDestination: DestinationMdl | null,
  freemiumDestination: DestinationMdl | null,
  directDestination: DestinationMdl | null
): Promise<any> => {
  return getSpotifyProfile(spotifyAccessToken).then((profile: SpotifyProfileModel) => {
    setGaSpotifyUserId(profile.id);
    if (profile.product === 'premium') {
      gaEventUserIsPremium();
    } else {
      gaEventUserIsFreemium();
    }
    if (directDestination) {
      // go to direct destination
      gaEventDestinationDirect(directDestination.url);
      sendUserToDestination(directDestination);
      return Promise.resolve();
    }
    if (profile.product === 'premium' && premiumDestination) {
      // go to premium destination
      gaEventDestinationPremium(premiumDestination.url);
      sendUserToDestination(premiumDestination);
      return Promise.resolve();
    }
    if (freemiumDestination) {
      // go to freemium destination
      gaEventDestinationFreemium(freemiumDestination.url);
      sendUserToDestination(freemiumDestination);
      return Promise.resolve();
    }
    console.warn(`no destination provided`);
    return Promise.reject();
  });
};

type Props = {
  children: any,
};

const SpotifyProfileLoader = ({ children }: Props) => {
  const {
    spotifyAccessToken,
    loadingSpotifyData,
    setLoadingSpotifyData,
    premiumDestination,
    freemiumDestination,
    directDestination,
  } = useGetPageContext();
  useEffect(() => {
    if (spotifyAccessToken) {
      setLoadingSpotifyData(true);
      fetchSpotifyProfileAndRedirect(
        spotifyAccessToken,
        premiumDestination,
        freemiumDestination,
        directDestination
      )
        .then(() => {})
        .catch(e => {
          console.warn(`fetchSpotifyProfileAndRedirect failed`, e);
          setLoadingSpotifyData(false);
        });
    }
  }, [spotifyAccessToken]);
  if (loadingSpotifyData) return <LoadingPage />;
  return children;
};

export default SpotifyProfileLoader;

// @flow
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { OutboundLink } from 'react-ga';
import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';
import VivaPageCountdown from '../PageLayout/components/VivaPage/components/VivaPageCountdown';

const HtmlToReact = require('html-to-react');

const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

// Order matters. Instructions are processed in
// the order they're defined
const processingInstructions = [
  {
    // This is REQUIRED, it tells the parser
    // that we want to insert our React
    // component as a child
    shouldProcessNode(node) {
      return (
        node.type === 'text' ||
        (node.attribs && node.attribs.class === 'countdown') ||
        (node.type === 'tag' && node.name === 'br')
      );
    },
    processNode(node, children, index) {
      if (node.type === 'text') {
        return node.data;
      }
      if (node.attribs && node.attribs.class === 'countdown') {
        return <VivaPageCountdown key={index.toString()} />;
      }
      if (node.type === 'tag' && node.name === 'br') {
        return <br key={index.toString()} />;
      }
      return processNodeDefinitions.processDefaultNode;
    },
  },
  {
    // Anything else
    shouldProcessNode(node) {
      return true;
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
];

const parseHtml = htmlParser({
  // isValidNode: node => node.type !== 'script',
  isValidNode: node => true,
  processingInstructions,
});

const MarkdownLink = ({ href, children, ...otherProps }: { href: string, children: any }) => {
  return (
    <OutboundLink
      eventLabel={ReactDOMServer.renderToStaticMarkup(children)}
      to={href}
      target="_blank"
      {...otherProps}
    >
      {children}
    </OutboundLink>
  );
};

type Props = {
  markdown: string,
};

const ParsedMarkdown = ({ markdown }: Props) => {
  return (
    <ReactMarkdown
      source={markdown}
      escapeHtml={false}
      astPlugins={[parseHtml]}
      renderers={{
        link: MarkdownLink,
      }}
    />
  );
};

export default ParsedMarkdown;

import { css } from 'emotion';
import circularBookWoff from '../assets/fonts/lineto-circular-book.woff';
import circularBookWoff2 from '../assets/fonts/lineto-circular-book.woff2';
import circularBookItalicWoff from '../assets/fonts/lineto-circular-bookItalic.woff';
import circularBookItalicWoff2 from '../assets/fonts/lineto-circular-bookItalic.woff2';
import circularMediumWoff from '../assets/fonts/lineto-circular-medium.woff';
import circularMediumWoff2 from '../assets/fonts/lineto-circular-medium.woff2';
import circularMediumItalicWoff from '../assets/fonts/lineto-circular-mediumItalic.woff';
import circularMediumItalicWoff2 from '../assets/fonts/lineto-circular-mediumItalic.woff2';
import circularBoldWoff from '../assets/fonts/lineto-circular-bold.woff';
import circularBoldWoff2 from '../assets/fonts/lineto-circular-bold.woff2';
import circularBoldItalicWoff from '../assets/fonts/lineto-circular-boldItalic.woff';
import circularBoldItalicWoff2 from '../assets/fonts/lineto-circular-boldItalic.woff2';
import circularBlackWoff from '../assets/fonts/lineto-circular-black.woff';
import circularBlackWoff2 from '../assets/fonts/lineto-circular-black.woff2';
import circularBlackItalicWoff from '../assets/fonts/lineto-circular-blackItalic.woff';
import circularBlackItalicWoff2 from '../assets/fonts/lineto-circular-blackItalic.woff2';

export default css`


@font-face {
  font-family: "Circular";
  src: url(${circularBookWoff2}) format("woff2"),
    url(${circularBookWoff}) format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Circular";
  src: url("${circularBookItalicWoff2}") format("woff2"),
    url("${circularBookItalicWoff}") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Circular";
  src: url("${circularMediumWoff2}") format("woff2"),
    url("${circularMediumWoff}") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Circular";
  src: url("${circularMediumItalicWoff2}") format("woff2"),
    url("${circularMediumItalicWoff}") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Circular";
  src: url("${circularBoldWoff2}") format("woff2"),
    url("${circularBoldWoff}") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Circular";
  src: url("${circularBoldItalicWoff2}") format("woff2"),
    url("${circularBoldItalicWoff}") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Circular";
  src: url("${circularBlackWoff2}") format("woff2"),
    url("${circularBlackWoff}") format("woff");
  font-weight: 800;
}

@font-face {
  font-family: "Circular";
  src: url("${circularBlackItalicWoff2}") format("woff2"),
    url("${circularBlackItalicWoff}") format("woff");
  font-weight: 800;
  font-style: italic;
}
`;

// @flow
import React from 'react';
import browserLang from 'browser-lang';
import queryString from 'query-string';

type Props = {
  children: any,
  pageContext: {
    locale: string,
    locales: Array<string>,
    pagePath: string,
  },
  location: {
    pathname: string,
  },
};

export const getLocalisedPath = (locale: string, pagePath: string): string => {
  return `/${locale}${pagePath}`;
};

const LocaleWrapper = ({ children, pageContext, location }: Props) => {
  if (typeof window === `undefined`) {
    return children;
  }
  const params = queryString.parse(window.location.search);
  if (params.noLocale) {
    return children;
  }
  const { locale, locales, pagePath } = pageContext;
  const { pathname } = location;
  const myLanguage = browserLang({
    languages: locales,
    fallback: 'en-US',
  });
  if (myLanguage !== locale && pathname === pagePath) {
    const localisedPath = getLocalisedPath(myLanguage, pagePath);
    try {
      window.location.replace(localisedPath);
    } catch (e) {
      window.location.href = localisedPath;
    }
  }
  return children;
};

export default LocaleWrapper;

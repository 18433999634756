// @flow
import queryString from 'query-string';
import { getEnvSpotifyClientId, getEnvSpotifyRedirectProxyUrl, useCMSIds } from '../utils/env';

const SPOTIFY_SCOPES = ['user-read-private'];
const SPOTIFY_CLIENT_ID = getEnvSpotifyClientId();
const SPOTIFY_REDIRECT_PROXY_URL = getEnvSpotifyRedirectProxyUrl();

export const getFilteredUrlParams = (): string => {
  if (typeof window === `undefined`) {
    return '';
  }
  const params = queryString.parse(window.location.search);
  const validParams = {};
  Object.keys(params).forEach(paramKey => {
    if (paramKey !== 'accessToken') {
      validParams[paramKey] = params[paramKey];
    }
  });
  return queryString.stringify(validParams);
};

const getRedirectUrl = (): [string, string] => {
  if (typeof window === `undefined`) {
    return [`${process.env.GATSBY_SITE_URL}/auth`, ''];
  }
  const validParams = getFilteredUrlParams();
  const host = window.location.origin;
  const { pathname } = window.location;
  return [`${host}/auth`, `${pathname}${encodeURIComponent(`?${validParams}`)}`];
};

const getSpotifyAuthUrl = (redirectUrl: string, state: string, spotifyClientId: string): string => {
  const clientId = useCMSIds() && spotifyClientId ? spotifyClientId : SPOTIFY_CLIENT_ID;
  return `https://accounts.spotify.com/en/authorize?response_type=token&scope=${encodeURIComponent(
    SPOTIFY_SCOPES.join(' ')
  )}&client_id=${encodeURIComponent(clientId)}&show_dialog=true&redirect_uri=${encodeURIComponent(
    redirectUrl
  )}&state=${state}`;
};

export const getAuthenticateSpotifyUserUrl = (spotifyClientId: string): string => {
  const [redirectUrl, state] = getRedirectUrl();
  const authUrl = getSpotifyAuthUrl(redirectUrl, state, spotifyClientId);
  return authUrl;
};

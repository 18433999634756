// @flow
import React, { useEffect, useState } from 'react';
import { OutboundLink } from 'react-ga';
import { get } from 'lodash';
import ReactMarkdown from 'react-markdown';
import { cx } from 'emotion';
import { ThemeProvider } from 'emotion-theming';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import parse from 'html-react-parser';
import * as styles from './styles';
import type { VivaPageMdl } from '../../../../cms/models/vivaPageLayout';
import { getAuthenticateSpotifyUserUrl } from '../../../../spotify/auth';
import VivaPageFooter from './components/VivaPageFooter';
import GlobalStyles from '../../../Website/components/GlobalStyles';
import {
  usePageComponentsGeneric,
  usePageComponentsTheme,
  usePageComponentsViva,
  useWebsiteDataContext,
} from '../../../WebsiteData';
import ParsedMarkdown from '../../../ParsedMarkdown';
import CountdownTimerContextWrapper from '../../../CountdownTimerContext';
import { RESPONSIVE_CLASSES } from '../../../../styles/utils/responsive';
import RapBackgroundShape from './components/RapBackgroundShape/RapBackgroundShape';
import { useGetPageContext } from '../../../Website/context/PageContext';
import { PageContainer } from './styles';
import { CallToAction } from './styles';
import { MobileHighlightedCopy } from './styles';

const getMobileSecondaryCopy = (page: VivaPageMdl): string => {
  return get(page, 'mobileSecondaryCopy.mobileSecondaryCopy', '');
};

const getMobilePrimaryCopy = (page: VivaPageMdl): string => {
  return get(page, 'mobilePrimaryCopy.mobilePrimaryCopy', '');
};

const getMobileHighlightedCopy = (page: VivaPageMdl): string => {
  return get(page, 'mobileHighlightedCopy.mobileHighlightedCopy', '');
};

const getMobileTopCopy = (page: VivaPageMdl): string => {
  return get(page, 'mobileTopCopy.mobileTopCopy', '');
};

const getPrimaryCopy = (page: VivaPageMdl): string => {
  return get(page, 'primaryCopy.primaryCopy', '');
};

const getSecondaryCopy = (page: VivaPageMdl): string => {
  return get(page, 'secondaryCopy.secondaryCopy', '');
};

const getCallToAction = (page: VivaPageMdl): string => {
  return get(page, 'callToActionButton', '');
};

const getCountdown = (page: VivaPageMdl): string => {
  return get(page, 'countdownTimer.date', '');
};

const getScriptsMetaTags = (page: VivaPageMdl): string => {
  return get(page, 'scriptsMetaTags.scriptsMetaTags', '');
};

const isRapCaviarTheme = (page: VivaPageMdl): boolean => {
  return page.pageTheme && page.pageTheme === 'Rap Caviar';
};

const isFrontLeftTheme = (page: VivaPageMdl): boolean => {
  return page.pageTheme && page.pageTheme === 'Front Left';
};

const isA1Theme = (page: VivaPageMdl): boolean => {
  return page.pageTheme && page.pageTheme === 'A1';
};

const useVivaBackgroundImage = (): string => {
  const pageComponents = usePageComponentsViva();
  if (!pageComponents) return '';
  return get(pageComponents, 'backgroundImage.file.url', '');
};

const useVivaMobileBackgroundImage = (): string => {
  const pageComponents = usePageComponentsViva();
  if (!pageComponents) return '';
  return get(pageComponents, 'mobileBackgroundImage.file.url', '');
};

const useVivaPeepsImage = (): string => {
  const pageComponents = usePageComponentsViva();
  if (!pageComponents) return '';
  return get(pageComponents, 'peepsImage.file.url', '');
};

const useVivaLogo = (): string => {
  const pageComponents = usePageComponentsViva();
  if (!pageComponents) return '';
  return get(pageComponents, 'vivaLogo.file.url', '');
};

const useVivaLogoDescription = (): string => {
  const pageComponents = usePageComponentsViva();
  if (!pageComponents) return '';
  return get(pageComponents, 'vivaLogo.description', '');
};

const useSpotifyLogo = (): string => {
  const pageComponents = usePageComponentsGeneric();
  if (!pageComponents) return '';
  return get(pageComponents, 'spotifyLogo.file.url', '');
};

const useSpotifyLogoMobile = (): string => {
  const pageComponents = usePageComponentsGeneric();
  if (!pageComponents) return '';
  return get(pageComponents, 'spotifyLogoMobile.file.url', '');
};

const useSpotifyClientId = (): string => {
  const { spotifyClientId = '' } = useWebsiteDataContext();
  return spotifyClientId;
};

type Props = {
  page: VivaPageMdl,
};

const VivaPage = ({ page }: Props) => {
  const [clientLoaded, setClientLoaded] = useState(false);

  useEffect(() => {
    setClientLoaded(typeof window !== `undefined`);
  }, [typeof window]);

  const { directDestination } = useGetPageContext();
  const pageId = get(page, 'id', '');
  const mobileTopCopy = getMobileTopCopy(page);
  const mobileHighlightedCopy = getMobileHighlightedCopy(page);
  const mobilePrimaryCopy = getMobilePrimaryCopy(page);
  const mobileSecondaryCopy = getMobileSecondaryCopy(page);
  const primaryCopy = getPrimaryCopy(page);
  const secondaryCopy = getSecondaryCopy(page);
  const callToAction = getCallToAction(page);
  const rapCaviarTheme = isRapCaviarTheme(page);
  const frontLeftTheme = isFrontLeftTheme(page);
  const a1Theme = isA1Theme(page);
  const scriptsMetaTags = getScriptsMetaTags(page);

  const backgroundImage = useVivaBackgroundImage();
  const mobileBackgroundImage = useVivaMobileBackgroundImage();
  const peepsImage = useVivaPeepsImage();
  const vivaLogo = useVivaLogo();
  const vivaLogoDescription = useVivaLogoDescription();
  const spotifyLogo = useSpotifyLogo();
  const spotifyLogoMobile = useSpotifyLogoMobile();
  const spotifyClientId = useSpotifyClientId();
  const authUrl = getAuthenticateSpotifyUserUrl(spotifyClientId);

  const ctaUrl = directDestination ? directDestination.url : authUrl;

  const ctaEventLabel = `CallToAction::${pageId}`;

  const ctaProps = directDestination
    ? {
        target: '_blank',
      }
    : {};

  const theme = usePageComponentsTheme();

  const buttonBgColor = theme && theme.buttonBackgroundColor ? theme.buttonBackgroundColor : '';
  const buttonTextColor = theme && theme.buttonTextColor ? theme.buttonTextColor : '';
  const shadeColor = theme && theme.shadeColor ? theme.shadeColor : '';

  return (
    <CountdownTimerContextWrapper timestamp={getCountdown(page)}>
      <GlobalStyles />
      <PageContainer
        className={cx({
          [styles.VIVA_PAGE_CLASSES.clientLoaded]: clientLoaded,
          [styles.VIVA_PAGE_CLASSES.themeVivaLatino]: !rapCaviarTheme && !a1Theme,
          [styles.VIVA_PAGE_CLASSES.themeRapCaviar]: rapCaviarTheme,
          [styles.VIVA_PAGE_CLASSES.themeFrontLeft]: frontLeftTheme,
          [styles.VIVA_PAGE_CLASSES.themeA1]: a1Theme,
        })}
        backgroundImage={backgroundImage}
        mobileBackgroundImage={mobileBackgroundImage}
      >
        {rapCaviarTheme && <RapBackgroundShape image={peepsImage} />}
        <main className={styles.mainClass} key={clientLoaded ? 'clientLoaded' : 'clientNotLoaded'}>
          <section className={styles.mainImageSectionClass}>
            {frontLeftTheme && (
              <div className={styles.inlinePeepsImageClass}>
                <img src={peepsImage} alt="Front Left" />
              </div>
            )}
            <div className={styles.mobileTopCopyClass}>
              <ParsedMarkdown markdown={mobileTopCopy} />
            </div>
            {!rapCaviarTheme && peepsImage && (
              <div
                className={cx(styles.peepsImageWrapperClass, {
                  [styles.peepsImageWrapperNoBgColorClass]: !!mobileBackgroundImage,
                })}
                style={{ backgroundImage: `url(${peepsImage})` }}
              >
                {/* <div> */}
                {/*  <img src={peepsImage} alt="Viva Latino" /> */}
                {/* </div> */}
              </div>
            )}
          </section>
          <section className={styles.copySectionClass}>
            <div className={RESPONSIVE_CLASSES.mobileOnly}>
              {mobileHighlightedCopy && (
                <MobileHighlightedCopy shadeColor={shadeColor}>
                  <ParsedMarkdown markdown={mobileHighlightedCopy} />
                </MobileHighlightedCopy>
              )}
              {mobilePrimaryCopy && (
                <div className={styles.mobilePrimaryCopyClass}>
                  <ParsedMarkdown markdown={mobilePrimaryCopy} />
                </div>
              )}
              {callToAction && (
                <CallToAction buttonBgColor={buttonBgColor} buttonTextColor={buttonTextColor}>
                  <OutboundLink eventLabel={ctaEventLabel} to={ctaUrl} {...ctaProps}>
                    {callToAction}
                  </OutboundLink>
                </CallToAction>
              )}
              {mobileSecondaryCopy && (
                <div className={styles.mobilePrimaryCopyClass}>
                  <ParsedMarkdown markdown={mobileSecondaryCopy} />
                </div>
              )}
            </div>
            <div className={RESPONSIVE_CLASSES.desktopOnly}>
              {primaryCopy && (
                <div className={styles.primaryCopyClass}>
                  <ParsedMarkdown markdown={primaryCopy} />
                </div>
              )}
              {callToAction && (
                <CallToAction buttonBgColor={buttonBgColor} buttonTextColor={buttonTextColor}>
                  <OutboundLink eventLabel={ctaEventLabel} to={ctaUrl} {...ctaProps}>
                    {callToAction}
                  </OutboundLink>
                </CallToAction>
              )}
              {secondaryCopy && (
                <div className={styles.secondaryCopyClass}>
                  <ReactMarkdown source={secondaryCopy} />
                </div>
              )}
            </div>
          </section>
          <div className={styles.vivaLogoClass}>
            <img src={vivaLogo} alt={vivaLogoDescription} />
          </div>
          <div className={styles.spotifyLogoClass}>
            <img className={RESPONSIVE_CLASSES.desktopOnly} src={spotifyLogo} alt="Spotify Logo" />
            <img
              className={RESPONSIVE_CLASSES.mobileOnly}
              src={spotifyLogoMobile}
              alt="Spotify Logo"
            />
          </div>
        </main>
        <div className={styles.footerClass}>
          <VivaPageFooter />
        </div>
      </PageContainer>
      {scriptsMetaTags && <Helmet>{parse(scriptsMetaTags)}</Helmet>}
    </CountdownTimerContextWrapper>
  );
};

export default VivaPage;

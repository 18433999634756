// @flow

import { css } from 'emotion';

export const mobileDesktopThreshold = 880;

export const BREAKPOINTS = {
  mobile: `@media (max-width: ${mobileDesktopThreshold - 1}px)`,
  desktop: `@media (min-width: ${mobileDesktopThreshold}px)`,
};

export const RESPONSIVE_CLASSES = {
  mobileOnly: css`
    ${BREAKPOINTS.desktop} {
      display: none;
    }
  `,
  desktopOnly: css`
    ${BREAKPOINTS.mobile} {
      display: none;
    }
  `,
};

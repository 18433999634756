// @flow

import { graphql } from 'gatsby';
import { get } from 'lodash';

export type DateMdl = {
  date: string,
};

export const getDateTimestamp = (date: DateMdl): string => {
  return get(date, 'date');
};

export type WebsiteDestinationMdl = {
  __typename?: string,
  url: string,
};

export const query = graphql`
  fragment Date on ContentfulDate {
    date
  }

  fragment WebsiteDestination on ContentfulWebsiteDestination {
    url
  }
`;

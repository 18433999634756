// @flow
import React, { useEffect } from 'react';
import { get } from 'lodash';
import queryString from 'query-string';
import { useGetPageContext } from '../../context/PageContext';
import { getFilteredUrlParams } from '../../../../spotify/auth';

const getAccessToken = (): string => {
  if (typeof window === `undefined`) {
    return '';
  }
  const params = queryString.parse(window.location.search);
  return get(params, 'accessToken', '');
};

const hasDontClearToken = (): boolean => {
  if (typeof window === `undefined`) {
    return false;
  }
  const params = queryString.parse(window.location.search);
  if (params.dontClear) {
    return true;
  }
  return false;
};

const clearAccessTokenFromUrl = () => {
  if (typeof window === `undefined`) {
    return;
  }
  const validParams = getFilteredUrlParams();
  try {
    window.history.replaceState({}, window.document.title, `?${validParams}`);
  } catch (e) {
    console.error(e);
  }
};

type Props = {
  children: any,
};

const SpotifyTokenDetector = ({ children }: Props) => {
  const { setSpotifyAccessToken } = useGetPageContext();
  useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken) {
      setSpotifyAccessToken(accessToken);
      if (!hasDontClearToken()) {
        clearAccessTokenFromUrl();
      }
    }
  }, []);
  return children;
};

export default SpotifyTokenDetector;

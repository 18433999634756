// @flow

import { css } from 'emotion';
import { getResponsiveFontSizeCss } from '../../../../../../styles/utils/typography';
import { BREAKPOINTS } from '../../../../../../styles/utils/responsive';
import { VIVA_PAGE_CLASSES } from '../../styles';

export const containerClass = css`
  display: flex;
  text-align: center;
  visibility: hidden;

  .${VIVA_PAGE_CLASSES.clientLoaded} & {
    visibility: visible;
  }

  ${BREAKPOINTS.desktop} {
    margin-bottom: 20px;
  }
`;

export const itemClass = css`
  min-width: 61px;
  margin-right: 20px;

  ${BREAKPOINTS.mobile} {
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const valueClass = css`
  ${getResponsiveFontSizeCss(40, 51)};
  font-weight: 700;

  ${BREAKPOINTS.mobile} {
    font-size: 36px;
  }
`;

export const labelClass = css`
  ${getResponsiveFontSizeCss(14, 18)};
  font-weight: 300;

  ${BREAKPOINTS.mobile} {
    font-size: 14px;
  }
`;

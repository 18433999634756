// @flow

import React from 'react';
import { get } from 'lodash';
import 'src/styles/global';
import LocaleWrapper from '../LocaleWrapper';
import { LayoutContext } from './context';
import MetaTagsWrapper from './components/MetaTagsWrapper';
import type { WebsiteMetaTagsMdl } from '../../cms/models/metaTags';

type Props = {
  children: any,
  slug?: string,
  pageContext?: {
    pagePathLocale: string,
  },
  location?: {},
  metaTags?: WebsiteMetaTagsMdl | null,
};

const Layout = ({ children, pageContext, location, metaTags, slug }: Props) => {
  return (
    <LayoutContext.Provider
      value={{
        pagePathLocale: get(pageContext, 'pagePathLocale', ''),
        websiteSlug: get(pageContext, 'websiteSlug', ''),
      }}
    >
      <LocaleWrapper pageContext={pageContext} location={location}>
        <MetaTagsWrapper metaTags={metaTags} slug={slug} />
        {children}
      </LocaleWrapper>
    </LayoutContext.Provider>
  );
};

Layout.defaultProps = {
  pageContext: {},
  location: {},
  metaTags: null,
};

export default Layout;

// @flow
import React, { useContext } from 'react';

export const CountdownTimerContext = React.createContext({
  timestamp: '',
});

export const useCountdownTimerTimestamp = (): string => {
  const { timestamp } = useContext(CountdownTimerContext);
  return timestamp;
};

type Props = {
  children: any,
  timestamp: string,
};

const CountdownTimerContextWrapper = ({ children, timestamp }: Props) => (
  <CountdownTimerContext.Provider value={{ timestamp }}>{children}</CountdownTimerContext.Provider>
);

export default CountdownTimerContextWrapper;

// @flow
import React from 'react';
import styled from 'react-emotion';
import { BREAKPOINTS } from '../../../../../../styles/utils/responsive';

const Container = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
`;

const Shape = styled('div')`
  position: absolute;
  top: 7vh;
  left: -500px;
  right: 0;
  bottom: 0;
  background-color: #d91c2f;
  transform: rotate(-30deg) translateY(168px);
  transform: rotate(-30deg) translateY(18.66vh);
  transform-origin: top right;

  ${BREAKPOINTS.mobile} {
    display: none;
  }
`;

const BgImage = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: contain;
  background-position: bottom left;
  background-repeat: no-repeat;

  ${BREAKPOINTS.mobile} {
    top: 12vh;
    bottom: auto;
    height: 100vh;
    background-position: top center;
  }
`;

const MobileShape = styled('div')`
  position: absolute;
  top: 54vh;
  left: 0;
  right: 0;
  ${BREAKPOINTS.desktop} {
    display: none;
  }

  svg {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const BottomShape = styled('div')`
  ${BREAKPOINTS.desktop} {
    display: none;
  }
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  svg {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const RapBackgroundShape = ({ image }) => (
  <Container>
    <BgImage style={{ backgroundImage: `url(${image})` }} />
    <Shape />
    <MobileShape>
      <svg width="375" height="568" viewBox="0 0 375 568" xmlns="http://www.w3.org/2000/svg">
        <g fill="#D91C2F" fillRule="evenodd">
          <path d="M-213.06 339.486l604.486-349 152 263.272-604.486 349z" />
          <path d="M-29.436 36.822l217 375.856-96.128 55.5-217-375.856z" />
        </g>
      </svg>
    </MobileShape>
    <BottomShape>
      <svg width="375" height="274" viewBox="0 0 375 274" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M-60.51 180.391L250.394.891l169 292.717-310.904 179.5z"
          fill="#D91C2F"
          fillRule="evenodd"
        />
      </svg>
    </BottomShape>
  </Container>
);

export default RapBackgroundShape;

import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout from '../components/Layout';
import type { WebsiteMdl } from '../cms/models/website';
import Website from '../components/Website';
import WebsiteData, { getWebsiteDataFromProps } from '../components/WebsiteData';
import { getMetaTagsFromWebsite } from '../components/Layout/components/MetaTagsWrapper';

type Props = {
  data: {
    website: WebsiteMdl,
    site: {
      siteMetadata: {
        title: string,
      },
    },
  },
};

export const getLayoutPageProps = (props: {
  location: {
    pathname: string,
  },
  pageContext: {
    [string]: any,
  },
}) => {
  const { pageContext = {}, location } = props;
  const slug = get(props, 'pageContext.websiteSlug', '');
  return { pageContext, location, slug };
};

class RootIndex extends React.Component<Props> {
  render() {
    console.log('this.props', this.props);
    const website: WebsiteMdl = get(this, 'props.data.website');
    return (
      <Layout {...getLayoutPageProps(this.props)} metaTags={getMetaTagsFromWebsite(website)}>
        <WebsiteData website={getWebsiteDataFromProps(this.props)}>
          <Website website={website} />
        </WebsiteData>
      </Layout>
    );
  }
}

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery($websiteSlug: String!, $locale: String!) {
    site {
      siteMetadata {
        title
      }
    }
    website: contentfulWebsite(slug: { eq: $websiteSlug }, node_locale: { eq: $locale }) {
      ...Website
    }
  }
`;

// @flow
import React, { useEffect } from 'react';
import { get } from 'lodash';
import VivaPage from './components/VivaPage';
import { setGaPageId } from '../../analytics';
import type { PageLayoutMdl } from '../../cms/models/pages';

const componentMapping = {
  ContentfulPageLayoutViva: VivaPage,
};

const getPageComponent = (pageLayout: PageLayoutMdl) => {
  const type = get(pageLayout, '__typename');
  const component = componentMapping[type];
  if (!component) {
    console.error(`No component matched for ${type}`);
    return null;
  }
  return component;
};

type Props = {
  pageLayout: PageLayoutMdl,
};

const PageLayout = ({ pageLayout }: Props) => {
  const pageId = get(pageLayout, 'id', '');
  useEffect(() => {
    setGaPageId(pageId);
  }, [pageId]);
  const PageComponent = getPageComponent(pageLayout);
  return <PageComponent page={pageLayout} />;
};

export default PageLayout;

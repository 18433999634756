// @flow
import React from 'react';
import { usePageComponentsTheme } from '../../../WebsiteData';

const GlobalStyles = () => {
  const theme = usePageComponentsTheme();
  const bgColor = theme && theme.backgroundColor ? theme.backgroundColor : '#509BF5';
  const color = theme && theme.textColor ? theme.textColor : '#FFFFFF';
  return (
    <style>
      {`
          body {
            background-color: ${bgColor};
            color: ${color};
          }
        `}
    </style>
  );
};

export default GlobalStyles;

// @flow

import { css } from 'emotion';
import styled from 'react-emotion';
import { getResponsiveFontSizeCss } from '../../../../styles/utils/typography';
import { BREAKPOINTS, RESPONSIVE_CLASSES } from '../../../../styles/utils/responsive';

export const VIVA_PAGE_CLASSES = {
  clientLoaded: 'clientLoaded',
  themeVivaLatino: 'themeVivaLatino',
  themeRapCaviar: 'themeRapCaviar',
  themeFrontLeft: 'themeFrontLeft',
  themeA1: 'themeA1',
};

export const containerClass = css`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: right bottom;
  position: relative;

  ${BREAKPOINTS.mobile} {
    display: block;
    height: auto;
    min-height: 100vh;
  }
`;

export const PageContainer = styled('div')`
  ${containerClass};
  
  ${BREAKPOINTS.desktop} {
    background-image: url('${props => (props.backgroundImage ? props.backgroundImage : '')}');
  }
  
  ${BREAKPOINTS.mobile} {
    background-image: url('${props =>
      props.mobileBackgroundImage ? props.mobileBackgroundImage : ''}');
    background-attachment: fixed;
  }
  background-size: cover;
  background-position: center;
  
`;

const bgImageCss = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: right bottom;
  pointer-events: none;
`;

export const desktopBgImageClass = css`
  ${RESPONSIVE_CLASSES.desktopOnly};
  ${bgImageCss};
  z-index: 5;
`;

export const mobileBgImageClass = css`
  ${RESPONSIVE_CLASSES.mobileOnly};
  ${bgImageCss};
  background-position: top center;
`;

export const mainClass = css`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;

  ${BREAKPOINTS.mobile} {
    display: block;
  }
`;

export const mainImageSectionClass = css`
  flex: 1;
  height: 100%;

  ${BREAKPOINTS.mobile} {
    min-height: 100vh;
    height: auto;
    position: relative;
    display: flex;
    align-items: flex-end;

    .${VIVA_PAGE_CLASSES.themeFrontLeft} & {
      display: block;
      height: auto;
      min-height: 0;
    }

    .${VIVA_PAGE_CLASSES.themeRapCaviar} & {
      min-height: 96.2vh;
    }

    .${VIVA_PAGE_CLASSES.themeA1} & {
      min-height: 80vh;
    }
  }
`;

export const inlinePeepsImageClass = css`
  ${BREAKPOINTS.desktop} {
    display: none;
  }
  padding-top: 60px;
  padding-bottom: 10px;

  img {
    width: 100%;
  }
`;

export const peepsImageWrapperClass = css`
  height: 100%;
  max-width: 800px;
  position: relative;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center;

  ${BREAKPOINTS.desktop} {
    .${VIVA_PAGE_CLASSES.themeA1} & {
      max-width: none;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .${VIVA_PAGE_CLASSES.themeFrontLeft} & {
    max-width: none;
    width: 100%;
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
  }

  ${BREAKPOINTS.mobile} {
    height: 100vh;
    justify-content: center;
    max-width: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    .${VIVA_PAGE_CLASSES.themeFrontLeft} & {
      display: none;
    }

    .${VIVA_PAGE_CLASSES.themeVivaLatino} & {
      background-size: 130%;
      background-position: bottom center;
      background-repeat: no-repeat;
      background-color: #f1682c;
    }

    .${VIVA_PAGE_CLASSES.themeA1} & {
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    display: block;

    ${BREAKPOINTS.desktop} {
      left: -30px;
      bottom: -60px;
    }
  }
`;

export const peepsImageWrapperNoBgColorClass = css`
  ${BREAKPOINTS.mobile} {
    .${VIVA_PAGE_CLASSES.themeVivaLatino} & {
      background-color: transparent;
    }
  }
`;

const copyStylingCss = css`
  ${getResponsiveFontSizeCss(20, 25)};
  font-weight: 500;

  iframe {
    width: 100%;
    height: 100%;
    display: block;

    .${VIVA_PAGE_CLASSES.themeA1} & {
      min-height: 300px;
      min-height: 50vh;
      min-height: calc(100vh - 250px);

      ${BREAKPOINTS.mobile} {
        min-height: 100vh;
        margin-top: -10vh;
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
      }
    }
  }

  h1 {
    ${getResponsiveFontSizeCss(60, 68)};
    font-weight: 800;
    margin-bottom: 40px;

    ${BREAKPOINTS.desktop} {
      .${VIVA_PAGE_CLASSES.themeA1} & {
        ${getResponsiveFontSizeCss(120, 152)};
      }
    }

    ${BREAKPOINTS.mobile} {
      ${getResponsiveFontSizeCss(48, 52, [200, 800, 36, 60])};

      .${VIVA_PAGE_CLASSES.themeRapCaviar} & {
        font-size: 48px;
        line-height: 52px;
      }

      .${VIVA_PAGE_CLASSES.themeA1} & {
        font-size: 60px;
        line-height: 45px;
      }
    }
  }

  h2 {
    ${BREAKPOINTS.desktop} {
      .${VIVA_PAGE_CLASSES.themeA1} & {
        ${getResponsiveFontSizeCss(50, 60)};
      }
    }
  }

  h3 {
    ${getResponsiveFontSizeCss(14, 18)};
    margin-bottom: 5px;

    ${BREAKPOINTS.desktop} {
      .${VIVA_PAGE_CLASSES.themeA1} & {
        ${getResponsiveFontSizeCss(20, 25)};
      }
    }

    &:not(:first-child) {
      margin-top: 60px;
    }
  }
  a {
    color: inherit;
  }
  strong {
    font-weight: 800;
  }
`;

export const copySectionClass = css`
  position: relative;
  z-index: 10;
  ${BREAKPOINTS.desktop} {
    margin: 0 80px;
    max-width: 505px;
    width: 100%;
  }

  ${BREAKPOINTS.mobile} {
    .${VIVA_PAGE_CLASSES.themeA1} & {
      h1,
      h2,
      h3,
      p {
        padding: 0 16px;
      }
    }
  }
`;

export const mobileHighlightedCopyClass = css`
  ${copyStylingCss};
  background-color: #ff6437;
  display: flex;
  justify-content: center;
  padding: 18px 0 18px 0;
  margin-bottom: 60px;
  text-align: center;

  .${VIVA_PAGE_CLASSES.themeRapCaviar} & {
    background-color: unset;
  }

  p {
    white-space: pre;
  }
`;

export const MobileHighlightedCopy = styled('div')`
  ${copyStylingCss};
  background-color: ${props => (props.shadeColor ? props.shadeColor : '#ff6437')};
  display: flex;
  justify-content: center;
  padding: 18px 0 18px 0;
  margin-bottom: 60px;
  text-align: center;

  .${VIVA_PAGE_CLASSES.themeRapCaviar} & {
    background-color: unset;
  }

  p {
    //white-space: pre;
  }
`;

export const mobilePrimaryCopyClass = css`
  ${copyStylingCss};
  text-align: center;
  padding: 0 32px;
`;

export const primaryCopyClass = css`
  ${copyStylingCss};
`;

export const secondaryCopyClass = css`
  ${copyStylingCss};
`;

export const CallToAction = styled('div')`
  margin: 40px 0 60px 0;
  ${getResponsiveFontSizeCss(18, 23)};
  font-weight: 700;

  ${BREAKPOINTS.mobile} {
    text-align: center;
  }

  a {
    color: inherit;
    text-decoration: none;
    background-color: ${props => (props.buttonBgColor ? props.buttonBgColor : '#ff6437')};
    color: ${props => (props.buttonTextColor ? props.buttonTextColor : 'inherit')};
    padding: 13px 44px;
    border-radius: 50px;
    display: inline-block;

    .${VIVA_PAGE_CLASSES.themeRapCaviar} & {
      background-color: #f7e6d2;
      color: #020202;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const vivaLogoClass = css`
  position: absolute;
  top: 20px;
  left: 30px;

  .${VIVA_PAGE_CLASSES.themeRapCaviar} & {
    top: 30px;
  }

  ${BREAKPOINTS.mobile} {
    top: 12px;
    left: 16px;

    .${VIVA_PAGE_CLASSES.themeRapCaviar} & {
      top: 28px;
    }

    .${VIVA_PAGE_CLASSES.themeA1} & {
      top: 20px;
      left: 15px;
    }
  }

  img {
    max-width: 138px;
  }
`;

export const spotifyLogoClass = css`
  position: absolute;
  top: 30px;
  right: 30px;

  ${BREAKPOINTS.mobile} {
    top: 24px;
    right: 16px;

    .${VIVA_PAGE_CLASSES.themeA1} & {
      top: 20px;
      right: 15px;
    }
  }
`;

export const footerClass = css`
  position: relative;
  z-index: 10;

  ${BREAKPOINTS.mobile} {
    margin-top: 60px;
  }

  .${VIVA_PAGE_CLASSES.themeA1} & {
    color: black;
  }
`;

export const footerDetailsClass = css`
  width: 40vw;
  padding-left: 20px;
`;

export const mobileTopCopyClass = css`
  ${copyStylingCss};
  ${RESPONSIVE_CLASSES.mobileOnly};
  padding-bottom: 5vh;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  z-index: 5;

  h1 {
    margin: 0;
  }

  .${VIVA_PAGE_CLASSES.themeA1} & {
    text-align: center;
    width: 100%;
  }
`;

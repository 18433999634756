// @flow
import React from 'react';
import { get } from 'lodash';
import * as styles from './styles';
import { usePageComponentsGeneric } from '../../../WebsiteData';

const useProfileLoadingMessage = (): string => {
  const pageComponents = usePageComponentsGeneric();
  if (!pageComponents) return '';
  return get(pageComponents, 'profileLoadingMessage', '');
};

const LoadingPage = () => {
  const profileLoadingMessage = useProfileLoadingMessage();
  return <div className={styles.containerClass}>{profileLoadingMessage}</div>;
};

export default LoadingPage;

// @flow
import React, { useState } from 'react';
import { get } from 'lodash';
import type { LandingPageMdl, PageLayoutMdl } from '../../../../cms/models/pages';
import PageLayout from '../../../PageLayout';
import PageContext from '../../context/PageContext';
import SpotifyTokenDetector from '../SpotifyTokenDetector';
import SpotifyProfileLoader from '../SpotifyProfileLoader';
import { useLayoutContext } from '../../../Layout/context';
import type { DestinationMdl } from '../../context/PageContext';

const getPageLayout = (landingPage: LandingPageMdl): PageLayoutMdl => {
  return get(landingPage, 'page[0]');
};

const getPremiumDestination = (landingPage: LandingPageMdl): DestinationMdl | null => {
  const url = get(landingPage, 'premiumDestination.url', '');
  if (!url) return null;
  return {
    url,
    external: true,
  };
};

export const getLocalisedPath = (pathName: string, locale: string): string => {
  if (!locale) return pathName;
  return `/${locale}${pathName}`;
};

export const getWebsiteSlugifiedPath = (localisedPath: string, websiteSlug: string): string => {
  const devMode = get(process, 'env.GATSBY_LOCAL_DEV', false);
  if (devMode) {
    return localisedPath;
  }
  return `/${websiteSlug}${localisedPath}`;
};

const getFreemiumDestination = (
  landingPage: LandingPageMdl,
  pagePathLocale: string,
  websiteSlug: string
): DestinationMdl | null => {
  const freemiumDestination = get(landingPage, 'freemiumDestination[0]', null);
  if (!freemiumDestination) {
    return null;
  }
  if (
    // eslint-disable-next-line dot-notation
    freemiumDestination['__typename'] &&
    // eslint-disable-next-line dot-notation
    freemiumDestination['__typename'] === 'ContentfulPremiumSignUpPage'
  ) {
    const slug = get(freemiumDestination, 'slug', '');
    const localisedPath = getLocalisedPath(`/${slug}`, pagePathLocale);
    return {
      url: localisedPath,
      external: false,
    };
    // return getWebsiteSlugifiedPath(localisedPath, websiteSlug);
  }
  const url = get(freemiumDestination, 'url', '');
  if (!url) {
    return null;
  }
  return {
    url,
    external: true,
  };
};

export const getDirectDestination = (landingPage: LandingPageMdl): DestinationMdl | null => {
  const url = get(landingPage, 'directDestination.url', '');
  if (!url) return null;
  return {
    url,
    external: true,
  };
};

type Props = {
  landingPage: LandingPageMdl,
};

const LandingPage = ({ landingPage }: Props) => {
  const pageLayout = getPageLayout(landingPage);
  const [loadingSpotifyData, setLoadingSpotifyData] = useState(false);
  const [spotifyAccessToken, setSpotifyAccessToken] = useState('');
  const { pagePathLocale, websiteSlug } = useLayoutContext();
  return (
    <PageContext.Provider
      value={{
        spotifyAccessToken,
        setSpotifyAccessToken,
        loadingSpotifyData,
        setLoadingSpotifyData,
        premiumDestination: getPremiumDestination(landingPage),
        freemiumDestination: getFreemiumDestination(landingPage, pagePathLocale, websiteSlug),
        directDestination: getDirectDestination(landingPage),
      }}
    >
      <SpotifyTokenDetector>
        <SpotifyProfileLoader>
          <PageLayout pageLayout={pageLayout} />
        </SpotifyProfileLoader>
      </SpotifyTokenDetector>
    </PageContext.Provider>
  );
};

export default LandingPage;

// @flow
import React from 'react';
import { get } from 'lodash';
import Helmet from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import type { WebsiteMdl } from '../../../../cms/models/website';
import type { WebsiteMetaTagsMdl } from '../../../../cms/models/metaTags';
import { includePixel } from '../../../../utils/env';

export const getMetaTagsFromWebsite = (website: WebsiteMdl): WebsiteMetaTagsMdl | null => {
  return get(website, 'metaTags', null);
};

type Props = {
  metaTags: WebsiteMetaTagsMdl | null,
  slug: string,
};

const MetaTagsWrapper = ({ metaTags, slug }: Props) => {
  if (!metaTags) return null;
  const pageTitle = get(metaTags, 'pageTitle', '');
  const shareImage = get(metaTags, 'shareImage.file.url', '');
  const additionalTags = get(metaTags, 'additionalMetaTags.additionalMetaTags', '');
  const includeA1Pixel = slug === 'a1-live';
  return (
    <Helmet title={pageTitle}>
      {includeA1Pixel ? (
        <script>
          {`
            !function(f,b,e,v,n,t,s)

            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            
            n.queue=[];t=b.createElement(e);t.async=!0;
            
            t.src=v;s=b.getElementsByTagName(e)[0];
            
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            
            'https://connect.facebook.net/en_US/fbevents.js');
            
            
            fbq('init', '1901136573502950'); 
            
            fbq('track', 'PageView');
            `}
        </script>
      ) : null}
      {includeA1Pixel ? (
        <noscript>
          {`<img
            height="1"
            width="1"
            src="https://www.facebook.com/tr?id=1901136573502950&ev=PageView

        &noscript=1"
          />`}
        </noscript>
      ) : null}
      {includePixel() ? (
        <script id="cidsyncjs" src="https://pixel-static.spotify.com/sync.min.js" async />
      ) : null}
      {includePixel() ? (
        <noscript>
          {`<img
            id="cidmgmt"
            src="https://pixel.spotify.com/v1/sync?nojs=1"
            style={{ display: 'none' }}
            width="0"
            height="0"
          />`}
        </noscript>
      ) : null}
      <meta property="og:image" content={shareImage} />
      <meta name="twitter:image" content={shareImage} />
      {ReactHtmlParser(additionalTags)}
    </Helmet>
  );
};

export default MetaTagsWrapper;

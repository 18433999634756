// @flow

import ReactGA from 'react-ga';

ReactGA.initialize(process.env.GATSBY_GOOGLE_ANALYTICS_ID, {
  titleCase: false,
});

const USER_CATEGORY = 'User';
const DESTINATION_CATEGORY = 'Destination';

export const setGaPageId = (pageId: string) => {
  ReactGA.set({
    'Page Id': pageId,
  });
};

export const setGaSpotifyUserId = (spotifyUserId: string) => {
  ReactGA.set({
    userId: spotifyUserId,
  });
  ReactGA.event({
    category: USER_CATEGORY,
    action: 'Authenticated',
    label: spotifyUserId,
  });
};

export const gaEventDestinationDirect = (destination: string) => {
  ReactGA.event({
    category: DESTINATION_CATEGORY,
    action: 'Direct',
    label: destination,
  });
};

export const gaEventDestinationPremium = (destination: string) => {
  ReactGA.event({
    category: DESTINATION_CATEGORY,
    action: 'Premium',
    label: destination,
  });
};

export const gaEventDestinationFreemium = (destination: string) => {
  ReactGA.event({
    category: DESTINATION_CATEGORY,
    action: 'Freemium',
    label: destination,
  });
};

export const gaEventUserIsPremium = () => {
  ReactGA.event({
    category: USER_CATEGORY,
    action: 'IsPremium',
  });
};

export const gaEventUserIsFreemium = () => {
  ReactGA.event({
    category: USER_CATEGORY,
    action: 'IsFreemium',
  });
};

// @flow
import fetch from 'isomorphic-fetch';

export type SpotifyProfileModel = {
  id: string,
  product: 'premium' | string,
};

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export const getSpotifyProfile = (accessToken: string): Promise<SpotifyProfileModel> => {
  return fetch('https://api.spotify.com/v1/me', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(checkStatus)
    .then(response => response.json())
    .catch(e => {
      console.warn(`getSpotifyProfile failed`, e);
      return Promise.reject();
    });
};

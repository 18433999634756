// @flow
import React, { useContext } from 'react';
import type { WebsiteMdl } from '../../cms/models/website';
import type {
  PageComponentsGenericMdl,
  PageComponentsMdl,
  PageComponentsThemeMdl,
  PageComponentsVivaMdl,
} from '../../cms/models/pageComponents';

export const getWebsiteDataFromProps = (props: { website: WebsiteMdl }): WebsiteMdl => {
  const { data } = props;
  const { website = {} } = data;
  return website;
};

export type WebsiteDataState = {
  website: WebsiteMdl,
};

export const WebsiteDataContext = React.createContext({
  website: null,
});

export const useWebsiteDataContext = (): WebsiteDataState => {
  const { website } = useContext(WebsiteDataContext);
  return website;
};

export const usePagesComponents = (): Array<PageComponentsMdl> => {
  const { pageComponents = [] } = useWebsiteDataContext();
  return pageComponents || [];
};

export const usePageComponentsGeneric = (): PageComponentsGenericMdl | null => {
  const pageComponents = usePagesComponents();
  return pageComponents.find(pageComponent => {
    // eslint-disable-next-line dot-notation
    return pageComponent['__typename'] === 'ContentfulPageComponentsGeneric';
  });
};

export const usePageComponentsViva = (): PageComponentsVivaMdl | null => {
  const pageComponents = usePagesComponents();
  return pageComponents.find(pageComponent => {
    // eslint-disable-next-line dot-notation
    return pageComponent['__typename'] === 'ContentfulPageComponentsEvent';
  });
};

export const usePageComponentsTheme = (): PageComponentsThemeMdl | null => {
  const pageComponents = usePagesComponents();
  return pageComponents.find(pageComponent => {
    // eslint-disable-next-line dot-notation
    return pageComponent['__typename'] === 'ContentfulPageComponentsTheme';
  });
};

type Props = {
  children: any,
  website: WebsiteMdl,
};

const WebsiteData = ({ children, website }: Props) => {
  return <WebsiteDataContext.Provider value={{ website }}>{children}</WebsiteDataContext.Provider>;
};

export default WebsiteData;

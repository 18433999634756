// @flow

import { css } from 'emotion';
import { getResponsiveFontSizeCss } from '../../../../../../styles/utils/typography';
import { BREAKPOINTS } from '../../../../../../styles/utils/responsive';
import { VIVA_PAGE_CLASSES } from '../../styles';

export const footerClass = css`
  background-color: #ff6437;

  ${BREAKPOINTS.desktop} {
    display: flex;
    padding: 0 30px;
    height: 85px;
    justify-content: space-between;
    align-items: center;
  }

  ${BREAKPOINTS.mobile} {
    padding: 40px 0 30px 0;
  }
`;

export const copyClass = css`
  //white-space: pre;
  font-size: 0.8em;

  strong {
    font-size: 1.1em;
  }

  ${BREAKPOINTS.mobile} {
    margin-top: 40px;
    text-align: center;

    .${VIVA_PAGE_CLASSES.themeRapCaviar} & {
      padding: 0 16px;
    }
  }
`;

export const detailsClass = css`
  ${getResponsiveFontSizeCss(12, 15)};
  font-weight: 300;

  ${BREAKPOINTS.desktop} {
    text-align: right;
  }

  ${BREAKPOINTS.mobile} {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const navClass = css`
  margin-top: 5px;
  a {
    display: inline-block;
    margin-left: 20px;

    ${BREAKPOINTS.mobile} {
      &:first-child {
        margin-left: 0;
      }
    }
  }
`;

export const sideDetailsClass = css`
  ${getResponsiveFontSizeCss(20, 25)};
  font-weight: 800;

  ${BREAKPOINTS.desktop} {
    display: flex;
  }

  ${BREAKPOINTS.mobile} {
    margin-bottom: 60px;
  }
`;

export const noSideDetailsClass = css`
  ${BREAKPOINTS.mobile} {
    display: none;
  }
`;

export const sponsorsClass = css`
  display: flex;
  align-items: center;

  ${BREAKPOINTS.desktop} {
    margin-right: 100px;
    min-width: 280px;
  }

  ${BREAKPOINTS.mobile} {
    justify-content: space-evenly;
  }

  a {
    display: inline-block;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

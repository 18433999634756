// @flow

import React, { useContext } from 'react';

export type LayoutContextState = {
  pagePathLocale: string,
};

export const LayoutContext = React.createContext({
  pagePathLocale: '',
});

export const useLayoutContext = (): LayoutContextState => {
  return useContext(LayoutContext);
};

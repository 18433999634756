// @flow

import { css } from 'emotion';

// $min-vw, $max-vw, $min-font-size, $max-font-size

const fluidFont = (sizes: [number, number, number, number]): string => {
  const [minVw, maxVw, minFs, maxFs] = sizes;
  return css`
    font-size: ${minFs}px;
    @media screen and (min-width: ${minVw}px) {
      font-size: calc(${minFs}px + ${maxFs - minFs} * ((100vw - ${minVw}px) / ${maxVw - minVw}));
    }

    @media screen and (min-width: ${maxVw}px) {
      font-size: $max-font-size;
    }
  `;
};

export const getResponsiveFontSizeCss = (
  fontSize: number,
  lineHeight?: number,
  fontSizes?: []
): string => {
  const lineHeightCss = lineHeight
    ? css`
        line-height: ${lineHeight / fontSize};
      `
    : '';
  const fluidCss = fontSizes ? fluidFont(fontSizes) : '';
  return css`
    ${lineHeightCss};
    font-size: ${fontSize}px;
    ${fluidCss};
  `;
};

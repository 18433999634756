// @flow

import { injectGlobal } from 'emotion';
import reset from './reset';
import fonts from './fonts';

export default injectGlobal`
    ${reset};
    ${fonts};
    
    * {
        box-sizing: border-box;
    }
    
    body {
        font-family: Circular, Helvetica, Arial, sans-serif;
    }
    
`;

// @flow
import React from 'react';
import { get } from 'lodash';
import ReactMarkdown from 'react-markdown';
import { cx } from 'emotion';
import * as styles from './styles';
import type { LinkMdl } from '../../../../../../cms/models/pageComponents';
import {
  usePageComponentsGeneric,
  usePageComponentsTheme,
  usePageComponentsViva,
} from '../../../../../WebsiteData';

const useSpotifyFooterLinks = (): Array<LinkMdl> => {
  const pageComponents = usePageComponentsGeneric();
  if (!pageComponents) {
    return [];
  }
  const { footerLinks = [] } = pageComponents;
  return footerLinks;
};

const useVivaFooterCopy = (): string => {
  const pageComponents = usePageComponentsViva();
  if (!pageComponents) return '';
  return get(pageComponents, 'footerDetails.footerDetails', '');
};

const getYear = (): string => {
  try {
    return new Date().getFullYear();
  } catch (e) {
    return '2019';
  }
};

export const getLinkImage = (link: LinkMdl): string => {
  return get(link, 'image.file.url', '');
};

export const getLinkUrl = (link: LinkMdl): string => {
  return get(link, 'url', '');
};

export const getLinkLabel = (link: LinkMdl): string => {
  return get(link, 'label', '');
};

const useVivaFooterSponsors = (): Array<LinkMdl> => {
  const pageComponents = usePageComponentsViva();
  if (!pageComponents) return [];
  const clients = get(pageComponents, 'footerClients', []);
  return clients || [];
};

const VivaPageFooter = () => {
  const footerLinks = useSpotifyFooterLinks();
  const footerCopy = useVivaFooterCopy();
  const footerSponsors = useVivaFooterSponsors();
  const theme = usePageComponentsTheme();
  const bgColor = theme && theme.footerBackgroundColor ? theme.footerBackgroundColor : '';
  return (
    <footer className={styles.footerClass} style={{ backgroundColor: bgColor }}>
      <section
        className={cx(styles.sideDetailsClass, {
          [styles.noSideDetailsClass]:
            !footerCopy && (!footerSponsors || footerSponsors.length === 0),
        })}
      >
        <section className={styles.sponsorsClass}>
          {footerSponsors.map((sponsor, index) => (
            <a
              href={getLinkUrl(sponsor)}
              target="_blank"
              rel="noopener noreferrer"
              key={index.toString()}
            >
              <img src={getLinkImage(sponsor)} alt={getLinkLabel(sponsor)} />
            </a>
          ))}
        </section>
        {footerCopy && (
          <section className={styles.copyClass}>
            <ReactMarkdown source={footerCopy} />
          </section>
        )}
      </section>
      <section className={styles.detailsClass}>
        <p>&copy; {getYear()} Spotify AB</p>
        <nav className={styles.navClass}>
          {footerLinks.map(({ label, url }) => (
            <a href={url} key={url} target="_blank" rel="noopener noreferrer">
              {label}
            </a>
          ))}
        </nav>
      </section>
    </footer>
  );
};

export default VivaPageFooter;

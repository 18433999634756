// @flow

declare var process: {
  env: {
    GATSBY_SPOTIFY_REDIRECT_PROXY_URL: string,
  },
};

export const getEnvSpotifyRedirectProxyUrl = (): string => {
  return process.env.GATSBY_SPOTIFY_REDIRECT_PROXY_URL;
};

export const getEnvSpotifyClientId = (): string => {
  return process.env.GATSBY_SPOTIFY_CLIENT_ID;
};

export const includePixel = (): boolean => {
  const { GATSBY_INCLUDE_PIXEL } = process.env;
  return (
    !!GATSBY_INCLUDE_PIXEL && (GATSBY_INCLUDE_PIXEL === true || GATSBY_INCLUDE_PIXEL === 'true')
  );
};

export const useCMSIds = (): boolean => {
  const { GATSBY_USE_CMS_IDS } = process.env;
  return !!GATSBY_USE_CMS_IDS && (GATSBY_USE_CMS_IDS === true || GATSBY_USE_CMS_IDS === 'true');
};

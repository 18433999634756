// @flow
import React, { useState } from 'react';
import { get } from 'lodash';
import type { WebsiteMdl } from '../../cms/models/website';
import type { LandingPageMdl } from '../../cms/models/pages';
import type { DateMdl } from '../../cms/models/misc';
import { getDateTimestamp } from '../../cms/models/misc';
import { hasDatePassed } from '../../utils/date';
import LandingPage from './components/LandingPage';
import { getUrlParam } from '../../utils/url';

type Props = {
  website: WebsiteMdl,
};

const getCurrentLandingPage = (website: WebsiteMdl): LandingPageMdl | null => {
  const urlParamPageIndex = getUrlParam('pg');
  const pages = get(website, 'landingPageSchedule', []).slice();
  if (urlParamPageIndex) {
    try {
      const index = parseInt(urlParamPageIndex, 10);
      if (index < pages.length) {
        const page = get(pages[index], 'landingPage');
        return page;
      }
    } catch (e) {
      console.warn(e);
    }
  }

  const reversedPages = pages.reverse();
  for (let i = 0, len = reversedPages.length; i < len; i++) {
    const pageEmbed = reversedPages[i];
    const page = get(pageEmbed, 'landingPage');
    const date: DateMdl | null = get(pageEmbed, 'publishedDate', null);
    if (i === len - 1 || !date) {
      return page;
    }
    const timestamp = getDateTimestamp(date);
    if (hasDatePassed(timestamp)) {
      return page;
    }
  }
  return null;
};

const Website = ({ website }: Props) => {
  const [landingPage, setLandingPage] = useState(getCurrentLandingPage(website));
  setInterval(() => {
    // every 5 seconds, re-run get current landing page - in case a new page is now available
    setLandingPage(getCurrentLandingPage(website));
  }, 5000);
  if (!landingPage) {
    console.error(`No Landing Page matched.`);
    return null;
  }
  return <LandingPage landingPage={landingPage} />;
};

export default Website;
